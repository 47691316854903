<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{cvCardTitle}}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="game_hmAdd">{{cvbtnAddNew}}</b-button>
            </template>
            <template v-slot:body>
              <b-row >
                <b-col class="col-12 col-sm-12 col-md-6 col-lg-7 mb-3">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    :placeholder="cvSearchText"
                  ></b-form-input>
                </b-col>
                <b-col class="col-8 col-sm-11 col-md-4 col-lg-4 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              </b-row>
              <b-row>
                <b-col class="container_minheight" md="12" v-if="game_hmObjList && game_hmObjList.length > 0">
                  <b-table
                    hover
                    responsive
                    :items="game_hmObjList"
                    :fields="columns"
                    :no-border-collapse="true"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :current-page="currentPage"
                    :per-page="perPage"
                  >
                    <template v-slot:cell(created_on)="data">
                      {{getFormattedDateTime(data.item.created_on)}}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="game_hmEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" @click="showGAME_HMDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
                <b-col class="container_minheight" md="12" v-else>
                  No data found
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="7" md="8" class="my-1"></b-col>
                <b-col sm="5" md="4" class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-modal
        v-model="showModelGAME_HMAdd"
        scrollable
        :title="cvAddModalHeader"
        size="xl"
      >
        <HangmanAdd :propOpenedInModal="true" @emitCloseGAME_HMAddModal="closeGAME_HMAddModal" />
        <template #modal-footer="">
          <b-button size="sm" class="pull-left" @click="game_hmAdd('FULLPAGE')">
            {{cvbtnModalExpand}}
          </b-button>
          <b-button size="sm" @click="closeGAME_HMAddModal()">
            {{cvbtnModalCancel}}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="showModelGAME_HMEdit"
        scrollable
        :title="cvEditModalHeader"
        size="xl"
      >
        <HangmanEdit :propOpenedInModal="true" :propGAME_HMObj="game_hmEditObj" @emitCloseGAME_HMEditModal="closeGAME_HMEditModal" />
        <template #modal-footer="">
          <b-button size="sm" class="pull-left" @click="game_hmEdit('FULLPAGE')">
            {{cvbtnModalExpand}}
          </b-button>
          <b-button size="sm" @click="closeGAME_HMEditModal()">
            {{cvbtnModalCancel}}
          </b-button>
        </template>
      </b-modal>

      <b-modal v-model="showModelGAME_HMDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
        <p>{{cvDelMessage}}</p>
        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" @click="showModelGAME_HMDelete = false">
            {{cvbtnModalCancel}}
          </b-button>
          <b-button size="sm" variant="primary" @click="game_hmDelete()">
            {{cvbtnModalDelOk}}
          </b-button>
        </template>
      </b-modal>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>
    </b-container>
  </template>

<script>
import { GameHm } from "../../../FackApi/api/gameHm.js"
import HangmanAdd from "./HangmanAdd"
import HangmanEdit from "./HangmanEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "HangmanList",
  components: {
    HangmanAdd,
    HangmanEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      cvCardTitle: "Hangman Wordlist(Game)",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GAME_HM",
      cvAddModalHeader: "Add GAME_HM",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GAME_HM List Response",
      showModelGAME_HMAdd: false,
      showModelGAME_HMEdit: false,
      showModelGAME_HMDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "word", key: "word", class: "text-left align-text-top", sortable: true },
        { label: "difficulty", key: "difficulty", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      game_hmObjList: null,
      game_hmEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      cvModuleFile: "app_game_hm",
      cvModulePrefix: "gameHm"
    }
  },
  mounted () {
    this.game_hmList()
  },
  methods: {
    /**
       * getFormattedDateTime
       */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
       * game_hmList
       */
    async game_hmList () {
      try {
        let gameHmListResp = await GameHm.game_hmList(this)
        if (gameHmListResp.resp_status) {
          this.game_hmObjList = gameHmListResp.resp_data.data
          this.totalRows = gameHmListResp.resp_data.count
        }
        else {
          this.toastMsg = gameHmListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at game_hmList() and Exception:", err.message)
      }
    },
    /**
       * game_hmAdd
       */
    game_hmAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/game_hm_add")
        }
        else {
          this.showModelGAME_HMAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at game_hmAdd() and Exception:", err.message)
      }
    },
    /**
       * game_hmEdit
       */
    game_hmEdit (type, item) {
      try {
        console.log("edit item", item)
        if (type === "FULLPAGE") {
          this.$router.push("/admin/game_hm_edit/" + this.game_hmEditObj.game_hm_id)
        }
        else {
          this.game_hmEditObj = item
          this.showModelGAME_HMEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at game_hmEdit() and Exception:", err.message)
      }
    },
    /**
       * showGAME_HMDeleteDialog
       */
    showGAME_HMDeleteDialog (item) {
      try {
        console.log("item", item)
        this.delObj = item
        this.showModelGAME_HMDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGAME_HMDeleteDialog() and Exception:", err.message)
      }
    },
    /**
       * game_hmDelete
       */
    async game_hmDelete () {
      try {
        let gameHmDelResp = await GameHm.game_hmDelete(this, this.delObj.game_hm_id)
        await ApiResponse.responseMessageDisplay(this, gameHmDelResp)

        if (gameHmDelResp && !gameHmDelResp) {
          this.showModelGAME_HMDelete = false
          return false
        }

        let index = this.game_hmObjList.indexOf(this.delObj)
        this.game_hmObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGAME_HMDelete = false
      }
      catch (err) {
        console.error("Exception occurred at game_hmDelete() and Exception:", err.message)
      }
    },
    /**
       * closeGAME_HMAddModal
       */
    closeGAME_HMAddModal (gameHmAddData) {
      try {
        if (gameHmAddData) {
          if (this.game_hmObjList && this.game_hmObjList.length >= 1) {
            let gameHmObjLength = this.game_hmObjList.length
            let lastId = this.game_hmObjList[gameHmObjLength - 1]["id"]
            gameHmAddData.id = lastId + 1
          }
          else {
            this.game_hmObjList = []
            gameHmAddData.id = 11111
          }

          gameHmAddData.created_on = moment()
          this.game_hmObjList.unshift(gameHmAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGAME_HMAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegame_hmAddModal() and Exception:", err.message)
      }
    },
    /**
       * closeGAME_HMEditModal
       */
    closeGAME_HMEditModal () {
      try {
        this.showModelGAME_HMEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGAME_HMEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
